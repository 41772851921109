import React, { useEffect, useState } from 'react';

import PhoneNumberInput from 'components/PhoneNumberInput';
import { useForm } from 'react-hook-form';
import { getMessageForInvalidFields, getMessageForRequiredFields } from 'utils/errors';
import FormContainer from 'components/LoanForm/FormContainer';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import { useDispatch, useSelector } from 'react-redux';
import { setYourContactData } from 'handlers/yourContact';
import { YourContactVariable } from 'enums/LoanFormVariables';
import { getYourContactData } from 'selectors/getYourContact';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FlowComponentType } from 'routes/types';
import { sendSmsCode } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import useCurrentFlow from 'hooks/useCurrentFlow';
import { getApplicationStep } from 'selectors/getApplicationStep';
import { YourPhoneNumberResult } from 'enums/FlowNextResults';

// import { METHOD_TESTING_PHONES } from 'utils/methodPhoneNumbers';

import styles from './YourPhoneNumber.module.scss';

export const PHONE_NUMBER_LENGTH = 20;

export enum YourContactInputLabel {
  PhoneNumber = 'Phone Number',
}

const YourPhoneNumber = ({ handleNext, navigationInfo }: FlowComponentType) => {
  const dispatch = useDispatch();
  const { isCardFlow } = useCurrentFlow();
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { phone_number: phoneNumber } = useSelector(getYourContactData);
  const { completedCustomize } = useSelector(getApplicationStep);

  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(isPossiblePhoneNumber(phoneNumber));
  const [shouldContinue, setShouldContinue] = useState<boolean>(false);
  const [isSendingCode, setIsSendingCode] = useState<boolean>(false);

  const {
    watch,
    register,
    formState: { errors, isValid },
    trigger,
    setValue,
    setError,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [YourContactVariable.PhoneNumber]: phoneNumber,
    },
  });

  const watcher = watch();

  const onBlur = () => {
    if (!phoneNumberIsValid) {
      return setError(YourContactVariable.PhoneNumber, {
        message: getMessageForInvalidFields(YourContactInputLabel.PhoneNumber),
      });
    }

    trigger(YourContactVariable.PhoneNumber);
  };

  const onChange = (value: string) => {
    let newIsValid;
    if (value) {
      newIsValid = isPossiblePhoneNumber(value);
      // setPhoneNumberIsValid(METHOD_TESTING_PHONES.includes(value as string) || isValidPhoneNumber(value));
    } else {
      newIsValid = false;
    }
    setPhoneNumberIsValid(newIsValid);

    setValue(YourContactVariable.PhoneNumber, value);
    trigger(YourContactVariable.PhoneNumber);
  };

  const handleContinue = async () => {
    dispatch(setYourContactData(watcher));
    setIsSendingCode(true);
    await dispatchWithUnwrap(sendSmsCode({ phoneNumber: watcher[YourContactVariable.PhoneNumber]! }));
    setIsSendingCode(false);
    setShouldContinue(true);
  };

  useEffect(() => {
    if (shouldContinue) {
      handleNext();
    }
  }, [shouldContinue]);

  useEffect(() => {
    register(YourContactVariable.PhoneNumber, {
      required: getMessageForRequiredFields(YourContactInputLabel.PhoneNumber),
    });
  }, [register]);

  return (
    <>
      <FormNavigation
        {...navigationInfo}
        {...(isCardFlow && { title: 'Sign Up' })}
        {...(completedCustomize && { onBackClick: () => handleNext(YourPhoneNumberResult.Exit) })}
      />
      <FormContainer
        title="Verify Your Identity"
        subtitle="You'll receive an authorization code. We won't contact you without consent."
      >
        <PhoneNumberInput
          label={YourContactInputLabel.PhoneNumber}
          placeholder="000-000-0000"
          errorMessage={errors[YourContactVariable.PhoneNumber]?.message}
          className={styles.formInput}
          name={YourContactVariable.PhoneNumber}
          onChange={onChange}
          onBlur={onBlur}
          value={watcher[YourContactVariable.PhoneNumber]}
          maxLength={PHONE_NUMBER_LENGTH}
          country="US"
          disabled={isSendingCode}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
            e.key === 'Enter' && isValid && phoneNumberIsValid && handleContinue()
          }
          autoFocus
        />

        <Button
          className={styles.button}
          disabled={!isValid || !phoneNumberIsValid}
          isLoading={isSendingCode}
          onClick={handleContinue}
        >
          Send Code
        </Button>
      </FormContainer>
    </>
  );
};

export default YourPhoneNumber;
